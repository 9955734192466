"use client";

import { cva, VariantProps } from "class-variance-authority";
import React from "react";

import { cn } from "./utils/tailwind";
import { PropsOf } from "./utils/types";

import { NavLink } from "./NavLink";

const buttonStyles = cva(
	"items-center inline-flex justify-center rounded border font-medium focus:ring-2 focus:ring-offset-2 focus:outline-none disabled:opacity-60 disabled:cursor-not-allowed",
	{
		variants: {
			size: {
				sm: "rounded-md px-3 py-2 text-sm leading-4",
				md: "rounded-md px-4 py-2 text-sm",
				lg: "rounded-md px-4 py-2 text-base",
				xl: "rounded-md px-6 py-2 text-lg",
			},
			color: {
				primary: "focus:ring-primary-500",
				secondary: "focus:ring-blue-500",
			},
			variant: {
				solid: "border-transparent shadow-sm",
				outline: "shadow-sm",
				ghost: "bg-transparent border-transparent bg-gray-50",
			},
		},
		compoundVariants: [
			{
				color: "primary",
				variant: "solid",
				className:
					"bg-gradient-to-br from-purple-600 to-blue-600 text-white [&:not(:disabled)]:hover:from-purple-700 [&:not(:disabled)]:hover:via-purple-500 [&:not(:disabled)]:hover:to-blue-600 [&:not(:disabled)]:active:from-purple-800 [&:not(:disabled)]:active:via-purple-600 [&:not(:disabled)]:active:to-blue-800",
			},
			{
				color: "primary",
				variant: "outline",
				className:
					"border-primary-600 text-primary-600 [&:not(:disabled)]:hover:bg-primary-50 [&:not(:disabled)]:active:bg-primary-100 disabled:bg-primary-50",
			},
			{
				color: "primary",
				variant: "ghost",
				className:
					"text-primary-600 [&:not(:disabled)]:hover:bg-primary-50 [&:not(:disabled)]:active:bg-primary-100",
			},
			{
				color: "secondary",
				variant: "solid",
				className:
					"bg-blue-600 text-white [&:not(:disabled)]:hover:bg-blue-700 [&:not(:disabled)]:active:bg-blue-800",
			},
			{
				color: "secondary",
				variant: "outline",
				className:
					"border-blue-600 text-blue-600 [&:not(:disabled)]:hover:bg-blue-50 [&:not(:disabled)]:active:bg-blue-100 disabled:bg-blue-50",
			},
			{
				color: "secondary",
				variant: "ghost",
				className:
					"text-blue-600 [&:not(:disabled)]:hover:bg-blue-50 [&:not(:disabled)]:active:bg-blue-100",
			},
		],
		defaultVariants: {
			size: "md",
			color: "primary",
			variant: "solid",
		},
	},
);

type ButtonRoots = "button" | "a" | typeof NavLink;
// Link causes error
// type ButtonRoots = 'button' | 'a' | typeof Link | typeof NavLink;

type CommonProps<Root extends ButtonRoots> = {
	as?: Root;
	leftIcon?: React.ReactNode;
	rightIcon?: React.ReactNode;
} & VariantProps<typeof buttonStyles>;

type RemoveDuplicatedProps<ComponentProps, PassedProps> = Omit<ComponentProps, keyof PassedProps>;

type Props<Root extends ButtonRoots> = CommonProps<Root> &
	// this removes next link "as" prop because CommonProps already has "as" prop
	RemoveDuplicatedProps<PropsOf<Root>, CommonProps<Root>>;

const defaultElement = "button";

export function Button<Root extends ButtonRoots = typeof defaultElement>({
	children,
	as,
	size,
	color,
	variant,
	leftIcon,
	rightIcon,
	...props
}: Props<Root>) {
	const As = as ?? "button";

	const { className, ...rest } = props;
	return (
		// @ts-ignore
		<As className={cn(buttonStyles({ size, color, variant }), className)} {...rest}>
			{leftIcon && <span className="mr-2">{leftIcon}</span>}
			{children}
			{rightIcon && <span className="ml-2">{rightIcon}</span>}
		</As>
	);
}
