"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { forwardRef } from "react";

import { cn } from "./utils/tailwind";
import { PropsOf } from "./utils/types";

interface Props extends PropsOf<typeof Link> {
	className?: string;
	activeClassName?: string;
}

export const NavLink = forwardRef<HTMLAnchorElement, Props>(
	({ href, className, activeClassName, children, ...props }, ref) => {
		const pathname = usePathname() ?? "";
		const isActive = pathname === href;

		return (
			<Link href={href} className={cn(className, isActive && activeClassName)} ref={ref} {...props}>
				{children}
			</Link>
		);
	},
);

NavLink.displayName = "NavLink";
